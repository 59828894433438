import { call, put } from "redux-saga/effects";

import * as api from "./api";
import * as actions from "../actions";
import * as login from "../../login";
import { organizationService } from "../../../services";

export const organization = {
  * getOrganizationUmbrella({ payload: { organizationId, view }}) {
    try {
      if (!["skeleton", "full"].includes(view)) {
        throw Error("Invalid view type");
      }

      const response = yield call(api.getOrganizationUmbrella, { organizationId, view });
      
      yield put(actions.organization.getOrganizationUmbrella.success({ payload: response.data, view } ));
    } catch (error) {
      yield put(actions.organization.getOrganizationUmbrella.error(error));
      throw error;
    }
  },

  * get(action) {
    try {
      const { organizationId } = action.payload;

      if (!organizationId) {
        throw Error("Cannot send organization request without an ID");
      }

      const response = yield call(api.get, organizationId);
      const payload = response ? response.data : {};

      yield put(actions.organization.get.success(payload));
    } catch (error) {
      yield put(actions.organization.get.error(error));
      throw error;
    }
  },

  * list() {
    try {
      const response = yield call(api.list);
      const payload = response ? response.data : {};
      yield put(actions.organization.list.success(payload));
    } catch (error) {
      yield put(actions.organization.list.error(error));
      throw error;
    }
  },

  * change(action) {
    const { organizationId } = action.payload;
    // debugger

    // TODO is this pick action needed? Ok maybe yield a organization pick that just does setCurrent
    // yield put(login.actions.session.organization.pick.request({ organizationId }));
    
    organizationService.setCurrent(organizationId);
    yield put(actions.organization.change.success());
    // Hacky but easiest way to handle reloading all resources
    window.location.reload();
  },

  * prepareDestroy(action) {
    try {
      const { organizationId } = action.payload;
      const response = yield call(api.prepareDestroy, { organizationId });
      yield put(actions.organization.destroy.prepare.success(response.data));
    } catch (error) {
      yield put(actions.organization.destroy.prepare.error(error));
      throw error;
    }
  },

  * destroy(action) {
    try {
      const { organizationId, destroyCode } = action.payload;
      const response = yield call(api.destroy, { organizationId, destroyCode });
      yield put(actions.organization.destroy.success(response.data));
      yield put(login.actions.session.logout.request());
    } catch (error) {
      if (error.response && error.response.status === 403) {
        yield put(actions.organization.destroy.error(error.response));
      } else {
        throw error;
      }
    }
  },
};
